html {
  scroll-behavior: smooth;
  /* width: 100%;
  overflow-x: hidden; */
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  background-color: #f2f1f0;
}

section#education,
section#skills {
  background-color: #ebe9e8;
}

section {
  padding: 20px 0;
}

.jumbotron > .slide-title {
  top: 25%;
  position: fixed;
  width: fit-content;
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.jumbotron .slide-title-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #7790a6;
  width: fit-content;
  color: #f2f1f0;
  margin-top: 0;
  margin-bottom: 5px;
  text-align: right;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .jumbotron h1 {
    font-size: 5em;
  }
  .jumbotron .slide-title-subtitle {
    font-size: 2em;
    white-space: nowrap;
  }

  .aboutme-left > div,
  .aboutme-left > div > a {
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 350px) {
  .jumbotron h1 {
    font-size: 2.4rem;
  }
}

.jumbotron .slide-title-subtitle {
  color: #f2f1f0;
  text-align: right;
  margin-top: 5px;
}

.title-red {
  color: #7790a6 !important;
}

.jumbotron {
  margin: 0;
  padding: 0;
  background-color: #010d26;
  width: 100%;
}

.arrow {
  color: #7790a6;
  z-index: 100;
  text-align: center;
  bottom: 5%;
  position: absolute;
  font-size: 5em;
  width: 100%;
  animation-name: bounce;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
}

@keyframes bounce {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0px);
  }
}

.arrow svg {
  color: #7790a6;
}

.arrow svg:hover {
  color: #485f73;
  cursor: pointer;
}

/* .big-arrow {
  font-size: 1000px;
  top: -500px;
  right: -500px;
  color: #733C0F;
  z-index: 14;
  transform: rotate(15deg);
  position: absolute;
} */

.slide_title {
  margin-right: 5%;
  white-space: nowrap;
}

header {
  text-align: center;
  color: #010d26;
  /* border-bottom: 1px dashed #7790A6; */
  margin-bottom: 40px;
}

header h2 {
  font-size: 3em;
}

.place-name h3 {
  color: #485f73;
}

.place-name p {
  color: #7790a6;
}

.place-name,
.aboutme-left {
  text-align: right;
  border-right: 1px dashed #7790a6;
}

.place-link {
  color: #7790a6;
}

.place-link:hover {
  color: #485f73;
}

.place p {
  margin-bottom: 0.2em;
}

.place {
  margin-bottom: 1em;
}

.container {
  padding: 40px 140px;
}

@media (max-width: 767px) {
  .container {
    padding: 60px 20px;
  }

  .place-name,
  .aboutme-left {
    text-align: left;
    border-right: none;
  }
  .school {
    border-left: 1px dashed #7790a6;
  }

  .contact-info {
    text-align: center;
  }
}

.aboutme-blurb {
  text-align: center;
}

.contact-info-header {
  text-align: center;
  color: #010d26;
}

.aboutme-list > div,
.aboutme-list > div > a {
  display: flex;
}
.aboutme-list svg {
  margin: 6px 4px 0 4px;
}

.aboutme-list > div,
.aboutme-list > div > a {
  color: #485f73;
}

.aboutme-list > div > a:hover {
  color: #7790a6;
}

/* .skills-list {
  float: left;
  position: relative;
  left: -50%;
} */

/* .skills-row {
  text-align: center;
} */

.skills-list span {
  color: #485f73;
}

#page-navbar {
  position: sticky;
  background-color: #010d26;
  width: fit-content;
  top: 50px;
  right: 50px;
  float: right;
  z-index: 100;
  text-align: center;
}

.page-navbar-scrollspy a {
  color: #f2f1f0;
  padding: 10px 20px;
  height: 50px;
}

.navbar-active {
  background-color: #7790a6;
}

.page-navbar-scrollspy {
  padding: 0;
  margin: 0;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  width: fit-content;
}

.topbar {
  height: 51px;
  width: 100%;
  margin-top: -50px;
  background-color: #010d26;
  top: -1px;
  position: sticky;
  z-index: 13;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-name {
  color: #f2f1f0;
  width: fit-content;
  display: inline-block;
  padding: 5px 10px;
}

.scrollspy-wrapper {
  display: flex;
  flex-direction: row;
}

.topbar-dropdown-scrollspy {
  padding: 0;
  margin: 0;
}

.topbar-dropdown {
  background-color: #010d26;
  width: 100%;
  position: fixed;
  top: 50px;
  z-index: 10;
  transition: height 500ms ease-out;
  overflow: hidden;
  height: 0;
}

.topbar-dropdown a {
  color: #f2f1f0;
  text-align: center;
  width: 100%;
  padding: 5px 0;
}

.hamburger-menu-button {
  color: #f2f1f0;
  border-color: #f2f1f0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 9px 20px 9px 9px;
}

@media (max-width: 700px) {
  .page-navbar-scrollspy {
    display: none;
  }
}

@media (min-width: 700px) {
  .hamburger-menu-button {
    display: none;
  }
  .topbar-dropdown {
    display: none;
  }
}

.hide-dropdown {
  width: 100%;
  position: absolute;
  height: 300px;
  background-color: #010d26;
  z-index: 12;
  top: 0;
}

.copyright {
  text-align: center;
  color: #7790a6;
  margin-top: 20px;
}

.showOldButton {
  all: unset;

  /* background-color: #7790a6; */
  color: #7790a6;
}

.showOldButtonWrapper {
  text-align: center;
  margin-top: 20px;
}
